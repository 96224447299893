<template>
  <div class="status-bar" :class="journeyType">
    <Car :size="20" v-if="driving" />
    <HumanDolly :size="20" v-if="!driving" />
    <p>{{ deliverStatus }}...</p>
  </div>
</template>

<script>
  import store from "@/store";

  export default {
    name: "StatusBar",

    computed: {
      deliverStatus() {
        return store.state.delivery.deliveryStatus;
      },
      journeyType() {
        return store.state.delivery.journeyType;
      },
      driving() {
        const type = store.state.delivery.journeyType;
        if (type === "dropping-off") {
          return false;
        }
        if (type === "picking-up") {
          return false;
        }
        return true;
      },
    },
  };
</script>
