<template>
  <div class="next-route">
    <p class="caption">Next</p>
    <p class="bold">{{ nextRoute }}</p>
    <ArrowRight :size="16" />
  </div>
</template>

<script>
  export default {
    name: "NextRoute",
    props: {
      nextRoute: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style lang="scss">
  .next-route {
    position: absolute;
    padding: 1rem 2rem 1rem 1rem;
    width: 100px;
    right: 0;
    top: 40px;
    color: $col_gray;
    p {
      font-size: 0.7rem;
      text-transform: uppercase;
      line-height: 1.3em;
      &.caption {
        margin-bottom: 0.1rem;
      }
      &.bold {
        font-weight: bold;
        color: $col_softblack;
      }
    }
  }
</style>
