<template>
  <button :class="style">{{ text }}</button>
</template>

<script>
  export default {
    name: "Button",
    props: {
      text: {
        type: String,
        default: "",
      },
      style: {
        type: Object,
        default() {
          return { primary: true, small: false, full: false };
        },
      },
    },
  };
</script>
