<template>
  <div>
    <section
      v-if="!showActiveCard"
      class="fixed notification"
      @click="showActiveCardHandler">
      <StatusBar />
    </section>
    <section
      v-if="showActiveCard"
      class="progress card fixed delivery-list_item"
      :class="journeyType">
      <StatusBar />
      <NextRoute
        v-if="nextJourney"
        :nextRoute="nextJourney"
        @click="goToNextStep" />
      <div class="item delivery">
        <div class="details">
          <Address
            flat="Flat 123"
            road="Stockwell Park Road"
            city="London"
            postcode="SW9 0IU" />
          <EstTime :journeyType="journeyType" />
        </div>
      </div>
      <div v-if="ifShowOrderDetails">show order detail</div>
      <div v-if="ifShowPickUpDetails">show pick up detail</div>
    </section>
    <div class="map fixed">
      <!-- <iframe
        style="border: 0"
        loading="lazy"
        allowfullscreen
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBHRFyIP-9c_7SC2sR4mnPx6n_I2ZBEJv0
        &q=Space+Needle,Seattle+WA"
      >
      </iframe> -->
    </div>
    <MobileNav :onDelivering="true" :nextStepBtnText="nextStepBtnText" />
  </div>
</template>

<script>
  import MobileNav from "@/components/MobileNav";
  import Address from "@/components/delivery/AddressSection";
  import EstTime from "@/components/delivery/EstTimeSection";
  import NextRoute from "@/components/delivery/NextRoute";
  import StatusBar from "@/components/delivery/StatusBar";
  import store from "@/store";

  export default {
    data() {
      return {
        showActiveCard: this.$route.query.active,
      };
    },
    computed: {
      nextStepBtnText() {
        return store.state.delivery.nextStepBtnText;
      },
      nextJourney() {
        return store.state.delivery.nextJourney;
      },
      journeyType() {
        return store.state.delivery.journeyType;
      },
      ifShowOrderDetails() {
        if (store.state.delivery.journeyType === "dropoff") {
          return true;
        }
        return false;
      },
      ifShowPickUpDetails() {
        if (store.state.delivery.journeyType === "pickup") {
          return true;
        }
        return false;
      },
    },
    methods: {
      goToNextStep() {
        store.dispatch("delivery/goToNextStep");
      },

      showActiveCardHandler() {
        this.showActiveCard = true;
      },
    },
    components: {
      MobileNav,
      Address,
      EstTime,
      NextRoute,
      StatusBar,
    },
  };
</script>

<style lang="scss">
  .progress {
    z-index: 2;
    margin-top: 3rem;
    padding: 0.5rem !important;
    .item {
      padding: 0.5rem !important;
    }
  }
</style>
