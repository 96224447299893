<template>
  <div class="address">
    <p>{{ flat }}</p>
    <p class="highlight">{{ road }}</p>
    <p>{{ city }} {{ postcode }}</p>
  </div>
</template>

<script>
  export default {
    name: "AddressSection",

    props: {
      flat: {
        type: String,
        default: "419 Coldharbour Ln",
      },
      road: {
        type: String,
        default: "419 Coldharbour Ln",
      },
      postcode: {
        type: String,
        default: "SW9 8LH",
      },
      city: {
        type: String,
        default: "LONDON",
      },
    },
  };
</script>

<style lang="scss">
  .address {
    font-size: 0.6875rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: $col_softblack;
  }

  .highlight {
    font-size: 1.25rem;
    font-weight: 600;
    color: $col_black;
  }
</style>
