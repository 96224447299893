<template>
  <nav class="mobile-nav">
    <ul class="menu" :class="{ delivering: isDelivering }">
      <li>
        <a class="column" href="/"><List :size="20" /> Deliveries</a>
      </li>

      <li
        v-if="!isDelivering"
        class="column align-center start-deliver relative">
        <a class="column pointer" @click="goToNextStep">
          <span class="tab round align-center justify-center">
            <Bike :size="20"
          /></span>
          Deliver
        </a>
      </li>
      <li v-if="!isDelivering">
        <a class="column" @click="hideListHandler">
          <MapMarker :size="20" /> Routes
        </a>
      </li>

      <li v-if="isDelivering">
        <Button
          :style="{ secondary: true, small: true }"
          :text="btnText"
          @click="goToNextStep" />
      </li>
    </ul>
  </nav>
</template>
<script>
  import Button from "@/components/Button";
  import store from "@/store";

  export default {
    props: {
      isDelivering: {
        type: Boolean,
        default: false,
      },
      hideListHandler: {
        type: Function,
        default() {},
      },
    },
    computed: {
      btnText() {
        return store.state.delivery.nextStepBtnText;
      },
    },
    methods: {
      goToNextStep() {
        store.dispatch("delivery/goToNextStep");
      },
    },
    components: {
      Button,
    },
  };
</script>
